import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Add } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import { useMutation, useNotify } from "ra-core";
import React from "react";
import { SimpleForm, TextInput } from "react-admin";
import { Booking } from "../../types";

interface Props {
  data: Booking;
}

const TopUpDialog: React.FC<Props> = (props) => {
  const { data } = props;
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const [transactionDatas, setTransactionDatas] = React.useState<
    { id: number; value: string }[]
  >([{ id: 1, value: "" }]); // Default satu form
  const [nextId, setNextId] = React.useState(2); // Start ID dari 2

  const handleTranscationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const newTransactionDatas = transactionDatas.map((transactionData) =>
      transactionData.id === id
        ? { ...transactionData, value: event.target.value }
        : transactionData
    );
    setTransactionDatas(newTransactionDatas);
  };

  const [handleAccept] = useMutation(
    {
      type: "update",
      resource: "bookings",
      payload: {
        id: data.id ?? "",
        data: {
          action: "top_up",
          transaction_numbers: transactionDatas
            .map((td) => td.value)
            .filter((value) => value !== ""),
        },
      },
    },
    {
      onSuccess: () => {
        notify(`Booking has been topped up.`, "info");
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTransactionDatas([]);
  };

  return (
    <>
      <Button
        color="primary"
        onClick={handleClickOpen}
        size={"small"}
        variant="contained"
      >
        3 Top Up Sets
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">Confirm the Top Up</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You will mark that the sets in this booking have been topped up.
            Confirm?
          </DialogContentText>
        </DialogContent>
        <SimpleForm toolbar={<></>}>
          {transactionDatas.map((transactionData, index) => (
            <Box
              key={transactionData.id}
              style={{ display: "flex", alignItems: "center" }}
            >
              <TextInput
                source={`transaction_numbers[${transactionData.id}]`}
                label={`Transaction Number ${index + 1}`}
                value={transactionData.value}
                onChange={(event) =>
                  handleTranscationChange(event, transactionData.id)
                }
              />
              {/* delete input button */}
              {transactionDatas.length > 1 && (
                <ClearIcon
                  style={{ color: "red" }}
                  onClick={() => {
                    // Pastikan setidaknya ada satu form
                    if (transactionDatas.length > 1) {
                      const newTransactionDatas = transactionDatas.filter(
                        (td) => td.id !== transactionData.id
                      );
                      setTransactionDatas(newTransactionDatas);
                    }
                  }}
                />
              )}
            </Box>
          ))}
          {/* add input  */}
          <Button
            startIcon={<Add />}
            style={{
              backgroundColor: "darkblue",
              color: "white",
            }}
            onClick={() => {
              setTransactionDatas([
                ...transactionDatas,
                { id: nextId, value: "" },
              ]);
              setNextId((prevId) => prevId + 1); // Increment the next ID for the next input
            }}
          >
            Add
          </Button>
        </SimpleForm>

        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAccept} color="primary">
            Confirm the Top Up
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TopUpDialog;
