/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { addHours, formatISO } from "date-fns";
import { values } from "lodash";
import moment from "moment";
import { useDataProvider } from "ra-core";
import React, { useContext, useEffect, useState } from "react";
import { Calendar, Event, momentLocalizer } from "react-big-calendar";
import { translateStatus } from "../../components/StatusField";
import { BookingStatus, stringify } from "../../pages/bookings/util";
import BookingSummary from "../../pages/chats/BookingSummary";
import { Booking } from "../../types";
import { getStyle } from "../../utils";
import { StatusColorContext } from "../contexts";
import "./styles.css";

interface Props {}
const BookingCalendar: React.FC<Props> = (props) => {
  const localizer = momentLocalizer(moment);
  const dataProvider = useDataProvider();
  const [dateFrom, setDateFrom] = useState<string>();
  const [dateTo, setDateTo] = useState<string>();
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { colors } = useContext(StatusColorContext);
  const dayLayoutAlgorithm = "no-overlap";
  const [totalEvents, setTotalEvents] = useState<number>(0);
  const [currentView, setCurrentView] = useState<string>("month");

  const onRangeChange = (range: Date[] | object) => {
    let startDate: Date | undefined;
    let endDate: Date | undefined;

    if (Array.isArray(range)) {
      // Untuk range dari array (misalnya tampilan bulan atau minggu)
      startDate = new Date(range[0]);
      endDate = new Date(range[range.length - 1]);
    } else if (typeof range === "object") {
      // Untuk range dari objek (misalnya tampilan hari)
      startDate = new Date(range.start);
      endDate = new Date(range.end);
    }

    if (startDate && endDate) {
      // Atur waktu startDate ke 00:00:00.000
      startDate.setHours(0, 0, 0, 0);

      // Atur waktu endDate ke 23:59:59.999
      endDate.setHours(23, 59, 59, 999);

      // Simpan range dalam format ISO
      setDateFrom(formatISO(startDate));
      setDateTo(formatISO(endDate));

      // Hitung total event dalam rentang
      const total = bookings.filter((booking) => {
        const bookingDate = new Date(booking.date);
        return bookingDate >= startDate && bookingDate <= endDate;
      }).length;

      setTotalEvents(total);
    }
  };

  const onViewChange = (view: string) => {
    setCurrentView(view);
  };

  // initialise the daterange to start and end of this week
  useEffect(() => {
    const start = moment().startOf("month").toDate();
    const end = moment().endOf("month").toDate();
    setDateFrom(formatISO(start));
    setDateTo(formatISO(end));
  }, []);

  /**
   * Map bookings to events
   */
  useEffect(() => {
    let ax = bookings.map((booking) => {
      const start = new Date(booking.date);
      const end = addHours(start, 3);
      return {
        title: stringify(booking),
        status: booking.status,
        date: booking.date,
        start,
        end,
        resource: booking,
      };
    });
    console.log("ax :>> ", ax);

    setEvents(ax);
  }, [bookings]);

  useEffect(() => {
    console.log("events :>> ", events);
  }, [events]);

  useEffect(() => {
    if (dateFrom && dateTo) {
      dataProvider
        .getList("bookings", {
          pagination: {
            page: 1,
            perPage: 1000,
          },
          sort: {
            field: "date",
            order: "asc",
          },
          filter: {
            date_from: dateFrom,
            date_to: dateTo,
          },
        })
        .then(({ data }) => {
          setBookings(data as Booking[]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [dateFrom, dateTo]);

  useEffect(() => {
    if (dateFrom && dateTo) {
      const startDate = new Date(dateFrom);
      const endDate = new Date(dateTo);

      const total = bookings.filter((booking) => {
        const bookingDate = new Date(booking.date);
        return bookingDate >= startDate && bookingDate <= endDate;
      }).length;

      setTotalEvents(total);
    }
  }, [bookings, dateFrom, dateTo, currentView]);

  /**
   * Show dialog when event in the calendar is selected
   * @param event
   */
  const handleSelectEvent = (event: Event) => {
    setSelectedEvent(event);
    setIsDialogOpen(true);
  };
  const handleClose = () => {
    setIsDialogOpen(false);
  };

  useEffect(
    () => console.log("selectedEvent :>> ", selectedEvent),
    [selectedEvent]
  );

  /**
   * Go to booking detail
   * @returns
   */
  const handleGoToBooking = () => {
    if (!selectedEvent) {
      return;
    }
    const win = window.open(
      `/#/bookings/${selectedEvent.resource.id}/show`,
      "_blank"
    );
    win?.focus();
  };

  return (
    <Box height="600px">
      <Calendar
        events={events}
        startAccessor="start"
        endAccessor="end"
        localizer={localizer}
        defaultView="day"
        views={["month", "week", "day", "agenda"]}
        onRangeChange={onRangeChange}
        dayLayoutAlgorithm={dayLayoutAlgorithm}
        eventPropGetter={(event: any, start, end, isSelected) => {
          const newStyle = getStyle(event.status, colors);

          return {
            className: "",
            style: newStyle,
          };
        }}
        onView={onViewChange}
        onSelectEvent={(event: Event) => handleSelectEvent(event)}
      />

      <Box mt={-1} mb={4}>
        <h4>Total Events: {totalEvents}</h4>
      </Box>

      {selectedEvent && (
        <Dialog
          open={isDialogOpen}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle>{selectedEvent.title}</DialogTitle>
          <DialogContent>
            <BookingSummary id={selectedEvent.resource?.id} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDialogOpen(false)} color="secondary">
              Close
            </Button>
            <Button onClick={handleGoToBooking} color="primary">
              Go To Booking
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Box
        style={{
          border: "1px solid #ddd",
          borderRadius: "3px",
          padding: "10px",
        }}
      >
        <h2>Legend</h2>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          {values(BookingStatus).map((status) => {
            return (
              <Chip
                label={translateStatus(status)}
                style={{
                  ...getStyle(status, colors),
                  minWidth: "100px",
                  height: "30px",
                  margin: "2px",
                }}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default BookingCalendar;
