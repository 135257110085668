import { Tooltip, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { CheckCircle, ErrorRounded } from "@material-ui/icons";
import { get } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { Booking, ProductInstance } from "../types";
import { getStyle } from "../utils";
import { StatusColorContext } from "./contexts";
import { translateStatus } from "./StatusField";

interface Props {
  resource: { id: number; status: string };
}

interface MyChipProps {
  resource: { id: number; status: string };
  resourceName: string;
  avatarCharacter?: string;
  style?: object;
  avatarAction?: string;
  tooltip?: React.ReactNode;
}
const MyChip: React.FC<MyChipProps> = ({
  resource,
  resourceName,
  avatarCharacter,
  style,
  avatarAction,
  tooltip,
}) => {
  const status = translateStatus(resource.status);
  const label = `${resource.id} ${status}`;
  const initial = avatarCharacter ?? resourceName.substring(0, 2).toUpperCase();
  const { colors } = React.useContext(StatusColorContext);

  return (
    <Box display="inline-block" style={{ padding: 1 }}>
      <Link
        to={`/${resourceName}/${resource.id}/show`}
        style={{
          ...getStyle(resource.status, colors),
          textDecoration: "none",
          display: "flex",
          padding: "2px",
          borderRadius: "16px",
          fontSize: "0.7rem",
          alignItems: "center",
          justifyContent: "center",
          ...style,
        }}
      >
        <Box
          style={{
            backgroundColor: "white",
            color: "#333",
            fontSize: "0.6rem",
            fontWeight: "bold",
            borderRadius: "50%",
            padding: "5px",
          }}
        >
          {initial}
        </Box>
        <Box
          style={{
            padding: "3px 5px ",
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          {label}
        </Box>

        {tooltip && (
          <Box
            style={{
              color: "#333",
              fontSize: "0.6rem",
              fontWeight: "bold",
            }}
          >
            {tooltip}
          </Box>
        )}

        {avatarAction && (
          <Box
            style={{
              backgroundColor: "white",
              color: "#333",
              fontSize: "0.6rem",
              fontWeight: "bold",
              borderRadius: "50%",
              padding: "5px",
            }}
          >
            {avatarAction}
          </Box>
        )}
      </Link>
    </Box>
  );
};

export const BookingChip: React.FC<{ resource: Booking }> = ({
  resource,
  ...rest
}) => (
  <>
    {resource.booking_issues ? (
      <MyChip
        tooltip={
          resource.booking_issues.length ? (
            <Tooltip
              title={
                <React.Fragment>
                  {resource.booking_issues.map((issue, index) => (
                    <Typography variant="body2" key={issue.id} color="inherit">
                      {index + 1}. {issue.status} - {issue.description}
                    </Typography>
                  ))}
                </React.Fragment>
              }
            >
              {
                // If all status are 'open'
                resource.booking_issues.every(
                  (issue) => issue.status === "open"
                ) ? (
                  <ErrorRounded color="error" />
                ) : // If all status are 'accepted'
                resource.booking_issues.every(
                    (issue) => issue.status === "accepted"
                  ) ? (
                  <CheckCircle color="primary" />
                ) : // If all status are 'rejected'
                resource.booking_issues.every(
                    (issue) => issue.status === "rejected"
                  ) ? (
                  <CheckCircle color="inherit" />
                ) : (
                  // If there are mixed status (open, accepted, or rejected)
                  <ErrorRounded color="inherit" />
                )
              }
            </Tooltip>
          ) : (
            ""
          )
        }
        resource={resource}
        resourceName="bookings"
        avatarCharacter="BK"
        {...rest}
      />
    ) : (
      <MyChip
        resource={resource}
        resourceName="bookings"
        avatarCharacter="BK"
        {...rest}
      />
    )}
  </>
);

export const AllocationChip: React.FC<Props> = ({ resource, ...rest }) => (
  <MyChip
    resource={resource}
    resourceName="allocations"
    avatarCharacter="AL"
    {...rest}
  />
);

export const DeliveryChip: React.FC<Props> = ({ resource, ...rest }) => (
  <MyChip
    resource={resource}
    resourceName="deliveries"
    avatarCharacter="DL"
    {...rest}
  />
);

export const ProductInstanceChip: React.FC<{ resource: ProductInstance }> = ({
  resource,
  ...rest
}) => {
  const backgroundColor = get(
    resource,
    "product.business_unit.color_hex",
    "#aaa"
  );

  return (
    <MyChip
      avatarAction={resource.is_consigned ? "CS" : ""}
      resource={resource}
      resourceName="product-instances"
      avatarCharacter="ST"
      style={{ backgroundColor }}
      {...rest}
    />
  );
};
