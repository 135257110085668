import Icon from "@material-ui/icons/CheckBox";
import moment from "moment-timezone";
import { FC } from "react";
import {
  AutocompleteInput,
  Datagrid,
  Edit,
  EditProps,
  Filter,
  FunctionField,
  List,
  ListProps,
  Loading,
  ReferenceInput,
  SelectInput,
  Show,
  ShowActionsProps,
  ShowProps,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { BookingCard, DeliveryCard } from "../../components/cards";
import CrudBreadcrumb from "../../components/CrudBreadcrumb";
import { Allocation } from "../../types";
import AssignButton from "./actions/AssignButton";
import CancelButton from "./actions/CancelButton";
import FinishButton from "./actions/FinishButton";
import GotIssueButton from "./actions/GotIssueButton";
import ResolveIssueButton from "./actions/ResolveIssueButton";
import UnAssignButton from "./actions/UnAssignButton";
// import DeliveryCard from "../../components/cards";
import {
  AllocationIdField,
  ProductInstanceIdField,
} from "../../components/fields";
import BookingField from "../../components/fields/BookingIdField";
import { AllocationStatus } from "./util";

const ResourceList: FC<ListProps> = (props) => (
  <List
    {...props}
    exporter={false}
    sort={{ field: "id", order: "desc" }}
    filters={<ListFilter />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <AllocationIdField />
      <BookingField source="booking" />
      <ProductInstanceIdField source="product_instance" />
      <TextField source="product.name" label="Product Name" />
      <FunctionField
        label="From"
        render={(record: any) =>
          record.booking.date
            ? moment(record.booking.date)
                .tz("Asia/Singapore")
                .format("DD/MM/YYYY - hh:mm A")
            : ""
        }
      />
      <FunctionField
        label="To"
        render={(record: any) =>
          record.end_time
            ? moment(record.end_time)
                .tz("Asia/Singapore")
                .format("DD/MM/YYYY - hh:mm A")
            : ""
        }
      />
    </Datagrid>
  </List>
);

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <TextInput source="id" label="ID" />
    <SelectInput
      source="status"
      choices={[
        { id: AllocationStatus.UNASSIGNED, name: "unassigned" },
        { id: AllocationStatus.GOT_ISSUE, name: "got_issue" },
        { id: AllocationStatus.ASSIGNED, name: "assigned" },
        { id: AllocationStatus.DONE, name: "done" },
        { id: AllocationStatus.CANCELLED, name: "cancelled" },
      ]}
    />
    <TextInput source="booking_id" />
    <TextInput source="product_instance_id" label="Set" />
    {/* <TextInput source="product_id.name" label="Product Name" /> */}
    <ReferenceInput
      label="Product Name"
      source="product_id"
      reference="products"
      sort={{ field: "name", order: "ASC" }}
    >
      <AutocompleteInput
        optionText="name"
        shouldRenderSuggestions={(val: string) => {
          return val.trim().length > 2;
        }}
      />
    </ReferenceInput>
  </Filter>
);

const ResourceShow: FC<ShowProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        mode="show"
        resourceId={props.id}
      />
      <Show {...props} actions={<ShowActions />}>
        <SimpleShowLayout>
          <AllocationIdField />
          <TextField source="product.name" label="Product Name" />
          <ProductInstanceIdField source="product_instance" />

          <BookingCard source="booking" />
          <FunctionField
            label="From"
            render={(record: any) =>
              record.booking.date
                ? moment(record.booking.date)
                    .tz("Asia/Singapore")
                    .format("DD/MM/YYYY - hh:mm A")
                : ""
            }
          />
          <FunctionField
            label="To"
            render={(record: any) =>
              record.end_time
                ? moment(record.end_time)
                    .tz("Asia/Singapore")
                    .format("DD/MM/YYYY - hh:mm A")
                : ""
            }
          />

          <DeliveryCard source="delivery" />
        </SimpleShowLayout>
      </Show>
    </>
  );
};

const ResourceEdit: FC<EditProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        mode="edit"
        resourceId={props.id}
      />
      <Edit {...props}>
        <SimpleForm redirect="show">
          <TextField source="id" />
          <TextInput source="name" />
        </SimpleForm>
      </Edit>
    </>
  );
};

const ShowActions: FC<ShowActionsProps> = (props) => {
  const { basePath, data, resource } = props;
  console.log(basePath, data, resource);
  const allocation = data as Allocation;

  if (!allocation) {
    return <Loading />;
  }

  return (
    <TopToolbar>
      <AssignButton data={allocation} />
      <UnAssignButton data={allocation} />
      <GotIssueButton data={allocation} />
      <ResolveIssueButton data={allocation} />
      <FinishButton data={allocation} />
      <CancelButton data={allocation} />
    </TopToolbar>
  );
};

const Common = {
  resourceName: "allocations",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: Common.resourceName,
  icon: Icon,
  list: ResourceList,
  show: ResourceShow,
  edit: ResourceEdit,
};
