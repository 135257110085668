import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Add, ClearRounded } from "@material-ui/icons";
import { DropzoneArea } from "material-ui-dropzone";
import { useMutation, useNotify } from "ra-core";
import React from "react";
import { SimpleForm, TextInput } from "react-admin";
import { Booking } from "../../types";
import { OrderActions } from "./util";

interface Props {
  data: Booking;
}

const SubmitReportDialog: React.FC<Props> = (props) => {
  const { data } = props;
  const [open, setOpen] = React.useState(false);
  const [files] = React.useState<File[]>([]);
  const [medias, setMedias] = React.useState<{ url: string; type: string }[]>(
    []
  );

  const [reportNote, setReportNote] = React.useState<string>();

  const [invoiceDatas, setInvoiceDatas] = React.useState<
    { id: number; total_amount: string; description: string }[]
  >([]);

  // const [note, setNote] = React.useState<string>();
  const [nextId, setNextId] = React.useState(1);

  const handleInvoiceChange = (
    id: number,
    field: "total_amount" | "description",
    value: string
  ) => {
    const updatedInvoiceDatas = invoiceDatas.map((invoiceData) =>
      invoiceData.id === id ? { ...invoiceData, [field]: value } : invoiceData
    );
    setInvoiceDatas(updatedInvoiceDatas);
  };

  const notify = useNotify();
  const [handleAccept] = useMutation(
    {
      type: "update",
      resource: "bookings",
      payload: {
        id: data.id ?? "",
        data: {
          action: OrderActions.SUBMIT_REPORT,
          medias,
          invoices: invoiceDatas
            .filter(
              (invoice) =>
                invoice.total_amount.trim() !== "" &&
                invoice.description.trim() !== ""
            )
            .map((invoice) => ({
              total_amount: invoice.total_amount,
              description: invoice.description,
            })),
          report_note: reportNote,
        },
      },
    },
    {
      onSuccess: () => {
        notify(`Successfully update the Booking.`, "info");
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const convertFileToBase64 = (file: File) => {
    var reader = new FileReader();
    return new Promise((resolve) => {
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        var base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const handleSelectFile = async (files: File[]) => {
    // convert file to base64
    const convertAll = async (files: File[]) =>
      Promise.all(
        files.map(async (file: File) => {
          return await convertFileToBase64(file);
        })
      );

    convertAll(files)
      .then((base64s) =>
        base64s.map((base64) => ({
          url: base64,
          type: "image",
        }))
      )
      .then((medias) => setMedias(medias as { url: string; type: string }[]));
  };

  React.useEffect(() => console.log("files :>> ", files), [files]);

  return (
    <>
      <Button
        color="primary"
        onClick={handleClickOpen}
        size={"small"}
        variant="contained"
      >
        Submit Report
      </Button>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        disableBackdropClick={true}
      >
        <DialogTitle id="alert-dialog-title">{"Submit Report"}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Upload report files related to the booking.
          </DialogContentText>

          <SimpleForm toolbar={<></>}>
            {invoiceDatas.map((invoiceData, index) => (
              <Box
                key={invoiceData.id}
                style={{ display: "flex", alignItems: "center", gap: 5 }}
              >
                <TextInput
                  source={`total_amount[${invoiceData.id}]`}
                  label={`Total Amount ${index + 1}`}
                  value={invoiceData.total_amount}
                  type="number"
                  onChange={(event) =>
                    handleInvoiceChange(
                      invoiceData.id,
                      "total_amount",
                      event.target.value
                    )
                  }
                />
                <TextInput
                  label={`Description ${index + 1}`}
                  source={`description[${invoiceData.id}]`}
                  value={invoiceData.description}
                  onChange={(event) =>
                    handleInvoiceChange(
                      invoiceData.id,
                      "description",
                      event.target.value
                    )
                  }
                />

                {/* delete input button */}
                <ClearRounded
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() => {
                    const newInvoiceDatas = invoiceDatas.filter(
                      (td) => td.id !== invoiceData.id
                    );
                    setInvoiceDatas(newInvoiceDatas);
                  }}
                />
              </Box>
            ))}
            {/* add input  */}
            <Button
              startIcon={<Add />}
              style={{
                backgroundColor: "darkblue",
                color: "white",
              }}
              onClick={() => {
                setInvoiceDatas([
                  ...invoiceDatas,
                  { id: nextId, total_amount: "", description: "" },
                ]);
                setNextId((prevId) => prevId + 1);
              }}
            >
              Add
            </Button>

            <TextInput
              label={`Report Note `}
              source={`report_note`}
              value={reportNote}
              multiline
              onChange={(event) => setReportNote(event.target.value)}
            />
          </SimpleForm>

          <DropzoneArea onChange={handleSelectFile} />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAccept} color="primary" autoFocus>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubmitReportDialog;
