import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useState } from "react";
import { useMutation, useNotify } from "react-admin";
import { Booking } from "../../types";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    padding: "1rem",
  },
});

const BookingIssue: React.FC<{ record?: Booking }> = ({ record }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const [bookingId, setBookingId] = useState<number>();
  const [actionType, setActionType] = useState("");
  const [handleAction, { loading }] = useMutation(
    {
      type: "update",
      resource: `bookings`,
      payload: {
        id: record?.id ?? "",
        data: {
          action: "update_issue",
          booking_issue_id: bookingId ?? "",
          issue_status: actionType,
        },
      },
    },
    {
      onSuccess: () => {
        notify(`Booking issue has been ${actionType}.`, "info");
        setOpen(false);
      },
      onFailure: (error) =>
        notify(`Error when updating the booking: ${error.message}`, "warning"),
    }
  );

  const handleClickOpen = (type: string, id: number) => {
    console.log("Action Type:", type);
    console.log("Booking ID:", id);
    setActionType(type);
    setBookingId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!record) return <>Loading...</>;
  const { booking_issues } = record;
  booking_issues.sort(
    (a, b) =>
      a.title.localeCompare(b.title) ||
      a.description.localeCompare(b.description)
  );

  // const getAllocations = (item: BookingItem, booking: Booking) => {
  //   return booking.allocations.filter(
  //     (allocation) => allocation.product_id === item.product_id
  //   );
  // };

  // const getDeliveries = (item: BookingItem, booking: Booking) => {
  //   return booking.deliveries.filter(
  //     (delivery) => delivery.product_instance.product_id === item.product_id
  //   );
  // };

  return (
    <>
      <TableContainer style={{ margin: "2rem auto" }}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {booking_issues.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.title}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>
                  {item.status === "open" ? (
                    <>
                      <Button
                        style={{ marginRight: "0.25rem" }}
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => handleClickOpen("accepted", item.id)}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => handleClickOpen("rejected", item.id)}
                      >
                        Reject
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      disabled
                      color="inherit"
                      onClick={() => handleClickOpen("rejected", item.id)}
                    >
                      {item.status === "accepted" ? "Accepted" : "Rejected"}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {actionType === "accepted"
            ? "Accept Booking Issue?"
            : "Reject Booking Issue?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {actionType === "accepted"
              ? "Are you sure you want to accept this booking issue?"
              : "Are you sure you want to reject this booking issue?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Back
          </Button>
          <Button
            onClick={handleAction}
            color="primary"
            disabled={loading}
            autoFocus
          >
            {loading ? "Please wait..." : `Confirm ${actionType}`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default BookingIssue;
