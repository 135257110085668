import { Tab, Tabs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/icons/Email";
import UnreadIcon from "@material-ui/icons/FiberManualRecord";
import React, { FC, useState } from "react";
import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  ListProps,
  SelectInput,
  TextField,
  TextInput,
  useDataProvider,
} from "react-admin";
import { useHistory } from "react-router-dom";
import { Inbox } from "../../types";
import { CaseCategory } from "../../utils/const";

const useStyles = makeStyles({
  detailContainer: {
    padding: "20px",
    lineHeight: "1.5rem",

    "& .title": {
      fontWeight: "bold",
      marginBottom: "5px",
    },
    "& .body": {
      marginBottom: "5px",
    },
  },
});

const resourceName = "inboxes";

interface BodyProps {
  id?: string;
  basePath?: string;
  record?: Inbox;
  [x: string]: any;
}
const InboxBody: FC<BodyProps> = ({ record, id, ...rest }) => {
  const history = useHistory();
  const classes = useStyles();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    if (id) {
      dataProvider.getOne("inboxes", { id });
    }
  }, [dataProvider, id]);

  if (!record) {
    return <>No detail is available.</>;
  }

  let url: string | null = null;
  let entity = null;
  let entityId = null;
  switch (record.data.payload_type) {
    case "App\\Models\\Booking":
      entityId = record.data.payload_id;
      url = `/bookings/${entityId}/show`;
      entity = `Booking`;
      break;

    case "App\\Models\\Issue":
      entityId = record.data.payload_id;
      url = `/issues/${entityId}/show`;
      entity = `Issue`;
      break;

    case "App\\Models\\Delivery":
      entityId = record.data.payload_id;
      url = `/deliveries/${entityId}/show`;
      entity = `Delivery`;
      break;

    case "App\\Models\\Allocation":
      entityId = record.data.payload_id;
      url = `/allocations/${entityId}/show`;
      entity = `Allocation`;
      break;
  }

  return (
    <div className={classes.detailContainer}>
      <div className="title">{`${entity} ${entityId}`}</div>
      <div className="body">{record?.data.content}</div>
      {url && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => history.push(url as any)}
          className="action"
        >
          Go to {entity}
        </Button>
      )}
    </div>
  );
};

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <TextInput
      source="search"
      label="Search Title and Content"
      alwaysOn
      style={{ minWidth: 300 }}
    />
    <SelectInput
      source="status"
      choices={[
        { id: "unread", name: "Unread" },
        { id: "read", name: "Read" },
      ]}
      alwaysOn
    />
  </Filter>
);

const TabbedResourceList: FC<ListProps> = (props: any) => {
  const [category, setCategory] = useState<string | undefined>(undefined);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setCategory(newValue);
  };

  const tabs = [
    { label: "All", value: undefined },
    { label: "New Case", value: CaseCategory.NEW_CASE },
    { label: "Amendment", value: CaseCategory.AMENDMENT },
    { label: "Report Submission", value: CaseCategory.CASE_REPORT_SUBMISSION },
    { label: "Others", value: CaseCategory.OTHERS },
  ];

  return (
    <>
      <Tabs
        style={{ marginTop: "20px", borderBottom: "2px solid #ddd" }}
        value={category}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <List
        {...props}
        filter={{ ...props.filter, category }}
        sort={{ field: "created_at", order: "DESC" }}
        exporter={false}
        filters={<ListFilter />}
        bulkActionButtons={false}
      >
        <Datagrid expand={<InboxBody />} rowClick="expand">
          <DateField source="created_at" label="Date" showTime />
          <TextField source="data.title" label="Title" />
          <FunctionField
            source="read_at"
            label=""
            render={(record: any) =>
              record.read_at ? (
                ""
              ) : (
                <UnreadIcon color="primary" fontSize="small" />
              )
            }
          />
        </Datagrid>
      </List>
    </>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: resourceName,
  icon: Icon,
  list: TabbedResourceList,
};
