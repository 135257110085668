import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import moment from "moment-timezone";
import React, { FC, useEffect } from "react";
import {
  Datagrid,
  FunctionField,
  List,
  ListProps,
  ReferenceField,
  TextField,
  useListController,
} from "react-admin";
import BookingCalendar from "../../components/BookingCalendar";
import { BookingIdField, HospitalRoomField } from "../../components/fields";
import BookingFilter from "./BookingFilter";

export const BookingList: FC<ListProps> = (props) => {
  const [isCalendarView, setIsCalendarView] = React.useState(false);

  const controller = useListController(props);

  const formatData = (data: any) => {
    if (data) {
      const sortedData = Object.values(data).sort((a: any, b: any) => {
        if (a.status === "new" && b.status !== "new") return -1;
        if (a.status !== "new" && b.status === "new") return 1;
        return 0;
      });

      return sortedData;
    } else {
      return {};
    }
  };

  useEffect(() => {
    formatData(controller.data);
  }, [controller.data]);

  return (
    <>
      <Box width="100%" textAlign="left">
        <Button
          variant="text"
          size="small"
          onClick={() => setIsCalendarView(!isCalendarView)}
        >{`Switch to ${isCalendarView ? "List" : "Calendar"} View`}</Button>
      </Box>

      {isCalendarView ? (
        <BookingCalendar />
      ) : (
        <List
          {...props}
          sort={{ field: "id", order: "DESC" }}
          exporter={false}
          bulkActionButtons={false}
          filters={<BookingFilter />}
        >
          <Datagrid>
            <BookingIdField />
            <FunctionField
              label="Date"
              render={(record: any) =>
                record.date
                  ? moment(record.date)
                      .tz("Asia/Singapore")
                      .format("DD/MM/YYYY hh:mm A")
                  : ""
              }
            />

            <ReferenceField source="consultant_id" reference="consultants">
              <TextField source="full_name" />
            </ReferenceField>
            <HospitalRoomField
              source="hospital_room.name"
              hospitalSource="hospital.name"
            />
            <ReferenceField source="procedure_id" reference="procedures">
              <TextField source="name" />
            </ReferenceField>
            <TextField source="surgeon.full_name" label="Surgeon" />
            <TextField source="business_unit.name" label="Business Unit" />
          </Datagrid>
        </List>
      )}
    </>
  );
};
