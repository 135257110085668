import { Box, Grid } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Labeled } from "ra-ui-materialui";
import React from "react";
import { Booking } from "../../types";
import { convertIsoToDateTime } from "../../utils/date";

const ReportItems: React.FC<{ record?: Booking }> = ({ record }) => {
  if (!record) return <>Loading...</>;
  const { reports } = record;

  if (reports.length === 0) {
    return (
      <Box display="flex" width="100%" height="100px" alignItems="center">
        <Box>There's no report uploaded to this booking yet.</Box>
      </Box>
    );
  }

  const columns = [
    { field: "invoice_number", headerName: "Invoice Number", flex: 1 },
    { field: "total_amount", headerName: "Invoice Amount", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
  ];

  const rows =
    record?.booking_invoices?.map((invoice, index) => ({
      id: index,
      invoice_number: invoice.invoice_number || "-",
      total_amount:
        new Intl.NumberFormat("en-SG", {
          style: "currency",
          currency: "SGD",
        }).format(invoice.total_amount) || "-",
      description: invoice.description || "-",
    })) || [];

  return (
    <Box sx={{ flexGrow: 1 }} mt={3}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Labeled label="Report Date">
            <span>
              {record.reports[0].created_at
                ? `${
                    convertIsoToDateTime(record.reports[0].created_at).date
                  }, ${convertIsoToDateTime(record.reports[0].created_at).time}`
                : "-"}
            </span>
          </Labeled>
          <Labeled label="Report Note">
            <span>{record.report_note ?? "-"}</span>
          </Labeled>

          {record.invoice_number && (
            <Labeled label="Invoice Number">
              <span>{record.invoice_number}</span>
            </Labeled>
          )}

          {record.invoice_amount && (
            <Labeled label="Invoice Amount">
              <span>
                {new Intl.NumberFormat("en-SG", {
                  style: "currency",
                  currency: "SGD",
                }).format(record.invoice_amount)}
              </span>
            </Labeled>
          )}
          <Labeled label="Report Note">
            <span>{record.report_note ?? "-"}</span>
          </Labeled>

          <Labeled label="Attachments">
            <ul>
              {reports.map((report, counter) => (
                <li>
                  <a
                    href={report.url}
                    target="_blank"
                    rel="noreferrer"
                  >{`Attachment ${counter + 1}`}</a>
                </li>
              ))}
            </ul>
          </Labeled>
        </Grid>

        <Grid item xs={6}>
          <Labeled label="Consultant ID">
            <span>{record.consultant?.id ?? "-"}</span>
          </Labeled>
          <Labeled label="Consultant">
            <span>{record.consultant?.full_name ?? "-"}</span>
          </Labeled>
        </Grid>
      </Grid>
      {record.booking_invoices.length > 0 && (
        <DataGrid
          style={{ marginBottom: "1rem" }}
          rows={rows}
          columns={columns}
          autoHeight
          disableSelectionOnClick
          hideFooter
          disableColumnMenu
          disableDensitySelector
        />
      )}
    </Box>
  );
};
export default ReportItems;
