import { Booking } from "../../types";

interface Props {
  record?: Booking;
  label?: string;
  addLabel?: boolean;
}

const BookingInvoice: React.FC<Props> = (props) => {
  const { record: booking } = props;

  if (!booking) {
    return <></>;
  }

  const { booking_invoices: BookingInvoice } = booking;
  if (BookingInvoice.length === 0) {
    return <></>;
  }

  return (
    <div>
      {props.record?.booking_invoices
        .map((invoice) => invoice.invoice_number)
        .join(", ")}
    </div>
  );
};
BookingInvoice.defaultProps = {
  label: "Invoice Number",
  addLabel: true,
};

export default BookingInvoice;
