import { Box, Button } from "@material-ui/core";
import React, { FC } from "react";
import {
  AutocompleteInput,
  Datagrid,
  Filter,
  FunctionField,
  List,
  ListProps,
  ReferenceInput,
  TextField,
  TextInput,
} from "react-admin";
import { ProductInstanceIdField } from "../../components/fields";
import StatusField from "../../components/StatusField";
import ProductInstanceBoard from "../ProductInstanceBoard";

const ResourceList: FC<ListProps> = (props) => {
  const [isBoardView, setIsBoardView] = React.useState(false);

  return (
    <>
      <Box width="100%" textAlign="left">
        <Button
          variant="text"
          size="small"
          onClick={() => setIsBoardView(!isBoardView)}
        >{`Switch to ${isBoardView ? "List" : "Board"} View`}</Button>
      </Box>

      {isBoardView ? (
        <ProductInstanceBoard />
      ) : (
        <List
          {...props}
          exporter={false}
          filters={<ListFilter />}
          bulkActionButtons={false}
          title="Product Sets"
        >
          <Datagrid>
            <ProductInstanceIdField />
            <FunctionField
              render={(record: any) => {
                return <></>;
              }}
            />
            <TextField source="product.name" label="Product Name" />
            <TextField source="location.name" label="Current Location" />
            <TextField source="product.business_unit.name" label="BU" />
            <StatusField source="status" />
          </Datagrid>
        </List>
      )}
    </>
  );
};

export default ResourceList;

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <TextInput source="id" label="Set ID" />
    <ReferenceInput
      label="Product Name"
      source="product_id"
      reference="products"
      perPage={10000}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Current Location"
      source="location_id"
      reference="locations"
      perPage={10000}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);
