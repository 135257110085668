import { Booking } from "../../types";

interface Props {
  record?: Booking;
  label?: string;
  addLabel?: boolean;
}

const BookingTopUp: React.FC<Props> = (props) => {
  const { record: booking } = props;

  if (!booking) {
    return <></>;
  }

  const { booking_topups: BookingTopUp } = booking;
  if (BookingTopUp.length === 0) {
    return <></>;
  }

  return (
    <div>
      {props.record?.booking_topups
        .map((bookingTopUp) => bookingTopUp.transaction_number)
        .join(", ")}
    </div>
  );
};
BookingTopUp.defaultProps = {
  label: "Top Up Transaction Number",
  addLabel: true,
};

export default BookingTopUp;
