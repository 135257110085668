import Box from "@material-ui/core/Box";
import { FC } from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin";
import CrudBreadcrumb from "../../components/CrudBreadcrumb";
import {
  AllocationIdField,
  BookingIdField,
  HospitalRoomField,
  ProductInstanceIdField,
} from "../../components/fields";
import StatusField from "../../components/StatusField";
import { Common } from "./index";

const ProductInstanceShow: FC<ShowProps> = (props) => {
  const { id } = props;

  return (
    <Box>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={props.id}
        mode="show"
      />
      <Show {...props} title={`Set Box #${props.id}`}>
        <TabbedShowLayout>
          <Tab label="Details">
            <ProductInstanceIdField sortBy="id" />
            <TextField source="product.name" label="Product Name" />
            <TextField source="location.name" label="Current Location" />
            <BooleanField source="is_one_time_use" label="One Time Use" />
            <BooleanField source="is_consigned" />
            <StatusField source="status" />
          </Tab>

          <Tab label="Allocation History">
            <Box>
              <List
                basePath="/allocations"
                resource="allocations"
                exporter={false}
                actions={<></>}
                bulkActionButtons={false}
                sort={{ field: "id", order: "desc" }}
                filter={{ product_instance_id: id }}
              >
                <Datagrid>
                  <AllocationIdField />
                  <DateField source="start_time" showTime />
                  <BookingIdField source="booking" />
                  <TextField
                    source="booking.procedure.name"
                    label="Procedure"
                  />
                  <HospitalRoomField
                    source="booking.hospital_room.name"
                    hospitalSource="booking.hospital.name"
                  />
                  <DateField
                    source="booking.date"
                    showTime
                    label="Booking Date"
                  />
                </Datagrid>
              </List>
            </Box>
          </Tab>

          <Tab label="Issues History">
            <Box>
              <List
                basePath="/issues"
                resource="issues"
                exporter={false}
                actions={<></>}
                bulkActionButtons={false}
                sort={{ field: "created_at", order: "desc" }}
                filter={{ product_instance_id: id }}
              >
                <Datagrid rowClick="show">
                  <TextField source="id" label="Issue ID" />
                  <DateField source="created_at" showTime={true} />
                  <TextField source="description" />
                  <StatusField source="status" />
                </Datagrid>
              </List>
            </Box>
          </Tab>
        </TabbedShowLayout>
      </Show>
    </Box>
  );
};

export default ProductInstanceShow;
