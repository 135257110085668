import { Box, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useMutation, useNotify } from "ra-core";
import React from "react";
import { SimpleForm, TextInput } from "react-admin";
import { Booking } from "../../types";

interface Props {
  data: Booking;
}

const SendInvoiceDialog: React.FC<Props> = (props) => {
  const { data } = props;
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const [invoiceDatas, setInvoiceDatas] = React.useState<
    { id: number; invoice_number: string; total_amount: number }[]
  >(data.booking_invoices || []); // Inisialisasi dengan data awal
  const [invoiceNumber, setInvoiceNumber] = React.useState<string>("");

  const handleInvoiceChange = (
    id: number,
    field: "invoice_number",
    value: string
  ) => {
    const updatedInvoiceDatas = invoiceDatas.map((invoiceData) =>
      invoiceData.id === id ? { ...invoiceData, [field]: value } : invoiceData
    );
    setInvoiceDatas(updatedInvoiceDatas);
  };

  const [handleAccept] = useMutation(
    {
      type: "update",
      resource: "bookings",
      payload: {
        id: data.id ?? "",
        data: {
          action: "send_invoice",
          invoices: invoiceDatas.map((td) => ({
            id: td.id,
            invoice_number: td.invoice_number,
          })),
          invoice_number: invoiceNumber,
        },
      },
    },
    {
      onSuccess: () => {
        notify(`Invoice has been submitted successfully.`, "info");
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        color="primary"
        onClick={handleClickOpen}
        size={"small"}
        variant="contained"
      >
        Submit Invoice
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Enter the Invoice Number
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please enter the invoice number that is created for this Booking.
            {/* <TextField
              label="Invoice Number"
              value={invoiceNumber}
              onChange={(e) => {
                setInvoiceNumber(e.target.value);
              }}
              autoFocus
              fullWidth
            /> */}
          </DialogContentText>

          {invoiceDatas.length === 0 && (
            <TextField
              label="Invoice Number"
              value={invoiceNumber}
              onChange={(e) => {
                setInvoiceNumber(e.target.value);
              }}
              autoFocus
              fullWidth
            />
          )}
        </DialogContent>

        <SimpleForm toolbar={<></>}>
          {invoiceDatas.map((invoice, index) => (
            <Box
              key={invoice.id}
              style={{ display: "flex", alignItems: "center", gap: 5 }}
            >
              <TextInput
                style={{ color: "green", fontWeight: "bold" }}
                source={`total_amount_${invoice.id}`}
                label={new Intl.NumberFormat("en-SG", {
                  style: "currency",
                  currency: "SGD",
                }).format(invoice.total_amount)}
                disabled
              />

              <TextInput
                source={`invoice_number_${invoice.id}`}
                label={`Invoice Number ${index + 1}`}
                value={
                  invoiceDatas.find((inv) => inv.id === invoice.id)
                    ?.invoice_number || ""
                }
                onChange={(event) =>
                  handleInvoiceChange(
                    invoice.id,
                    "invoice_number",
                    event.target.value
                  )
                }
              />
            </Box>
          ))}
        </SimpleForm>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAccept} color="primary">
            Submit Invoice
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SendInvoiceDialog;
