export const ProductType = {
  INSTRUMENT: "instrument",
  IMPLANT: "implant",
  OTHERS: "others",
};

export const UserStatus = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  PENDING: "pending",
};

export const AllocationStatus = {
  NEW: "new",
  READY: "ready",
};


export const CaseCategory = {
  NEW_CASE: "new_case",
  AMENDMENT: "amendment",
  CASE_REPORT_SUBMISSION: "case_report_submission",
  OTHERS: "others",
};