import Box from "@material-ui/core/Box";
import moment from "moment";
import React, { FC } from "react";
import {
  FunctionField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin";
import bookings from ".";
import CrudBreadcrumb from "../../components/CrudBreadcrumb";
import { BookingIdField, HospitalRoomField } from "../../components/fields";
import { Booking } from "../../types";
import Allocate from "./allocations/Allocate";
import { ShowActions } from "./BookingActions";
import BookingInvoice from "./BookingInvoices";
import BookingIssue from "./BookingIssue";
import BookingItems from "./BookingItems";
import BookingRevision from "./BookingRevision";
import BookingTopUp from "./BookingTopUp";
import Delivery from "./Deliveries";
import ReportItems from "./ReportItems";

export const BookingShow: FC<ShowProps> = (props) => {
  // const dataProvider = useDataProvider();

  // const fetchBookingTopUp = async () => {
  //   try {
  //     if (!props.id) {
  //       console.error("Missing id in props");
  //       return;
  //     }
  //     const response = await dataProvider.getOne("bookings/", {
  //       id: props.id,
  //     });
  //     console.log(response.data);
  //   } catch (error) {
  //     console.error("Error fetching extra data:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchBookingTopUp();
  // }, [props.id]);

  return (
    <>
      <CrudBreadcrumb
        resourceName={bookings.name}
        mode="show"
        resourceId={props.id}
      />
      <Show {...props} actions={<ShowActions />}>
        <TabbedShowLayout>
          <Tab label="summary">
            <BookingIdField />
            <TextField source="consultant.full_name" label="Consultant" />
            <FunctionField
              label="Date"
              render={(record: any) =>
                record.date
                  ? moment(record.date)
                      .tz("Asia/Singapore")
                      .format("DD MMMM yyyy - hh:mm A")
                  : ""
              }
            />
            <TextField source="surgeon.full_name" label="Surgeon" />
            <HospitalRoomField
              source="hospital_room.name"
              hospitalSource="hospital.name"
            />
            <TextField source="procedure.name" label="Procedure" />

            <TextField source="additional_items" />

            <TextField source="remarks" />

            <BookingInvoice />

            <BookingTopUp />

            <BookingRevision />
          </Tab>
          <Tab label="Booking Issue">
            <BookingIssue {...props} />
          </Tab>
          <Tab label="Booking Items">
            <BookingItems {...props} />
          </Tab>
          <Tab label="Allocations">
            <Allocations />
          </Tab>
          <Tab label="Deliveries">
            <Delivery />
          </Tab>
          {/* We don't need chat tabs for now */}
          {/* <Tab label="Chat">
            <Chat />
          </Tab> */}
          <Tab label="Reports">
            <ReportItems {...props} />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};

interface TabContentProps<T> {
  record?: T;
}

/**
 * Allocation window
 *
 * @param props
 * @returns
 */
const Allocations: React.FC<TabContentProps<Booking>> = (props) => {
  const { record: booking } = props;

  if (!booking) {
    return <Box>Loading Booking...</Box>;
  }

  return <Allocate bookingId={booking.id} />;
};
